<template>
    <img
        :src="image || '/IMAGE-NOT-YET-AVAILABLE.png'"
        :alt="alt"
        :class="{
            foil: props.is_foil,
            classOverride,
        }"
        @error="($event) => ($event.target.src = '/IMAGE-NOT-YET-AVAILABLE.png')"
    />
</template>

<script setup>
    const props = defineProps({
        image: {
            required: false,
            type: String,
        },
        is_foil: {
            required: false,
            type: Boolean,
            default: false,
        },
        classOverride: {
            default: "block w-auto",
        },
        alt: {
            default: "Cover Image",
        },
    });
</script>

<style scoped>
    .foil {
        display: inline-block;
        mask: linear-gradient(-60deg, #000 30%, rgba(0, 0, 0, 0.56), #000 70%) right/350% 100%;
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        100% {
            mask-position: left;
        }
    }
</style>
